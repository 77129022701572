import React from 'react';
import { IClassName } from '@frontend/jetlend-core/src/models/common';
import styles from './Skeleton.module.scss';
import { mergeClassNames } from '@ui/utils/classNameUtils';

export type IProps = IClassName;

/**
 * Элемент загрузки — переливающийся блок
 */
export default function Skeleton({ className }: IProps) {
    return (
        <div className={mergeClassNames([styles['container'], className])} />
    );
};
