'use client';

import React, {
    PropsWithChildren,
    useEffect,
} from 'react';
import Skeleton from '@frontend/jetlend-web-ui/src/ui/Skeleton/Skeleton';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './Section.module.scss';

export interface IProps extends PropsWithChildren {
    hideTitle?: boolean;
    filled?: boolean;
}

const SectionSkeleton: React.FC<IProps> = props => {
    const {
        children,
        hideTitle,
        filled = true,
    } = props;
    const headerRequired = !hideTitle;

    const sectionClassNames = buildClassNames(styles, [
        'section',
        filled && 'section--filled',
    ]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className={sectionClassNames}>
            {headerRequired && (
                <div className={styles['section__header']}>
                    <Skeleton className={styles['section__header--skeleton']} />
                </div>
            )}
            <div className={styles['section__body']}>
                {children}
            </div>
        </section>
    );
};

export default SectionSkeleton;